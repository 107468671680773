<template>
  <div class="container" style="width: 100%;max-width: 600px;">
    <div class="wrapper">
      <div class="header">
        <img src="../assets/safety.svg" alt="" />
      </div>
      <div class="buttons" style="margin-top: 35px;">
        <button
          @click="handleSocial"
          class="btn"
          style="display: inline-block; margin: 15px 5px;
          font-size: 14px;"
        >
          <span class="btn-align"
            >Get Social<img
              src="../assets/right-arrow.svg"
              class="btn-icon-align"
          /></span>
        </button>

        <button
          @click="handleRedeemPrize"
          class="btn"
          style="display: inline-block; margin: 15px 5px;
          font-size: 14px;"
        >
          <span class="btn-align"
            >Redeem Prize<img
              src="../assets/right-arrow.svg"
              class="btn-icon-align"
          /></span>
        </button>

        <button
          @click="handlePLD"
          class="btn"
          style="display: inline-block; margin: 15px 5px;
          font-size: 14px;"
        >
          <span class="btn-align"
            >PLD Delivery Schedule<img
              src="../assets/right-arrow.svg"
              class="btn-icon-align"
          /></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleSocial() {
      window.location.href = "https://social.thdsafety.com";
    },
    handlePLD() {
        this.$router.push({ name: "PLD" });
    },
    handleRedeemPrize() {
      this.$router.push({ name: "Redeem" });
    },
  },
};
</script>
