import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    step: 1,
    prizeCode: "",
    prize: "",
    shirtSize: "",
  },
  mutations: {
    incrementStep(state) {
      state.step++;
    },
    decrementStep(state) {
      if (state.step != 1) {
        state.step--;
      }
    },
    setStepZero(state) {
      state.step = 1;
    },
    setPrize(state, prize) {
      state.prize = prize;
    },
    setPrizeCode(state, prizeCode) {
      state.prizeCode = prizeCode;
    },
    setShirtSize(state, size) {
      state.shirtSize = size;
    },
  },
  actions: {},
  modules: {},
});
