<template>
  <div class="container">
    <div class="wrapper">
      <div class="header">
        <img src="../assets/safety.svg" alt="" />
      </div>
      <div class="progress">
        <ProgressCounter />
      </div>
      <div class="content">
        <RedeemCode v-if="step == 1" />
        <Winner v-if="step == 2" />
        <ClaimPrize v-if="step == 3" />
        <ThankYou v-if="step == 4" />
      </div>
      <a class="need-assistance" @click="handleClickAssistance"
        >Need assistance? Contact us.</a
      >
    </div>
  </div>
</template>

<script>
import RedeemCode from "../components/RedeemCode.vue";
import Winner from "../components/Winner.vue";
import ClaimPrize from "../components/ClaimPrize.vue";
import ThankYou from "../components/ThankYou.vue";
import ProgressCounter from "../components/ProgressCounter.vue";

export default {
  name: "Home",
  components: {
    RedeemCode,
    Winner,
    ClaimPrize,
    ThankYou,
    ProgressCounter,
  },
  data() {
    return {};
  },
  computed: {
    step: function() {
      return this.$store.state.step;
    },
  },
  methods: {
    handleClickAssistance() {
      this.$router.push("/contact");
    },
  },
};
</script>

<style>
@import "../assets/global.scss";

.container {
  text-align: center;
  margin: 0px auto;
  width: 428px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;
}
.wrapper {
  padding: 60px;
}
.header {
  width: 300px;
  height: 125px;
  margin: 0 auto;
}
.header img {
  max-width: 300px;
  outline: 3px solid white;
  padding: 20px;
}
.content {
  padding: 10px;
}
.need-assistance {
  color: #f48126;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 450px) {
  .container {
    width: 100%;
  }
  .wrapper {
    padding: 60px 10px;
  }
}
</style>
