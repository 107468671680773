<template>
  <div class="thank-you-container">
    <h1>Thank you! Prizes will be mailed out every 60 days.</h1>
    <button @click="resetForm" class="btn">Exit</button>
  </div>
</template>

<script>
export default {
  name: "ThankYou",
  methods: {
    resetForm: function() {
      this.$store.commit("setPrize", "");
      this.$store.commit("setPrizeCode", "");
      this.$store.commit("setStepZero");
    },
  },
};
</script>

<style>
.thank-you-container h1 {
  font-size: 14px;
  font-weight: bold;
}
</style>
