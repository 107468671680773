<template>
  <div class="redeem-code-container">
    <h1>Enter the code found below the prize icon.</h1>
    <div class="input-container">
      <input
        type="text"
        :class="error ? `error-input` : ''"
        placeholder="Prize Code"
        v-model="prizeCode"
      />
      <p class="error-text" v-if="error">
        {{ errorMessage }}
      </p>
    </div>

    <button class="btn" @click="submitCodeHandler">
      <span class="btn-align"
        >Submit<img src="../assets/right-arrow.svg" class="btn-icon-align"
      /></span>
    </button>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "RedeemCode",
  data() {
    return {
      prizeCode: "",
      error: false,
      errorMessage: "",
    };
  },
  created: function() {
    this.$store.commit("setShirtSize", "");
  },
  methods: {
    submitCodeHandler: async function() {
      try {
        this.error = false;
        const validCode = await axios.post(
          `${process.env.VUE_APP_BASE_API_URL}/prizes`,
          { prizeCode: this.prizeCode }
        );
        let prize = validCode.data.prize;
        let prizeCode = validCode.data.prizeCode;
        this.$store.commit("setPrize", prize);
        this.$store.commit("setPrizeCode", prizeCode);
        this.$store.commit("incrementStep");
      } catch (error) {
        this.error = true;
        this.errorMessage = error.response.data.error;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../assets/global.scss";

.redeem-code-container h1 {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.redeem-code-container input[type="text"] {
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 10px;
  width: 125px;
  box-sizing: border-box;
  text-align: center;
  margin-top: 30px;
}

.input-container {
  display: block;
  width: 100%;
}
.redeem-code-container ::placeholder {
  font-size: 18px;
  color: #afafaf;
}

.btn-icon-align {
  margin-left: 20px;
}
</style>
