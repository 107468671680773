<template>
  <div class="claim-prize-container">
    <h1>Submit the following information to receive your prize.</h1>
    <form @submit.prevent="submitForm">
      <input type="text" placeholder="First Name" v-model="firstName" />
      <input type="text" placeholder="Last Name" v-model="lastName" />
      <input type="text" placeholder="Scratcher Issued By" v-model="issuedBy" />
      <select name="reason" id="" v-model="reason">
        <option class="placeholder" value="" disabled selected>Reason</option>
        <option value="ppe">PPE</option>
        <option value="safe lifting">Safe Lifting</option>
        <option value="zone of safety">Zone of Safety</option>
        <option value="using the correct equipment">
          Using the Correct Equipment
        </option>
        <option value="ladder safety">Ladder Safety</option>
        <option value="slip, trip, fall hazards">
          Slip, Trip, Fall Hazards
        </option>
        <option value="hydration">Hydration</option>
        <option value="cleaning/wellness">Cleaning/Wellness</option>
        <option value="other">Other</option>
      </select>
      <input
        type="text"
        v-if="reason == 'other'"
        placeholder="What was the reason?"
        v-model="reasonOtherDescription"
      />
      <select name="business unit" id="" v-model="businessUnit">
        <option class="placeholder" value="" disabled selected>
          Business Unit
        </option>
        <option value="store">Store</option>
        <option value="met">MET</option>
        <option value="supply chain">Supply chain</option>
        <option value="home services">Home Services</option>
        <option value="retail equipment services">
          Retail/Equipment Services
        </option>
        <option value="other">Other</option>
      </select>

      <div v-if="groupNumber == 1">
        <input
          type="text"
          placeholder="4-digit store number"
          v-model="storeNumber"
          :class="storeNumberError ? `error-input` : ''"
          @input="debounceVerifyStoreNumber"
        />
        <p class="error-text" v-if="storeNumberError">
          {{ storeNumberErorrMessage }}
        </p>
        <div
          class="confirm-store-location"
          v-if="!storeNumberError && storeNumberStoreData"
        >
          <h1>
            Please confirm this is the correct store or re-enter a different
            store number above.
          </h1>
          <div class="confirm-store-location-address">
            <div>
              <h2>{{ storeNumberStoreData.address }}</h2>
              <h2>
                {{ storeNumberStoreData.city }},
                {{ storeNumberStoreData.state }}
                {{ storeNumberStoreData.province }}
                {{ storeNumberStoreData.zipcode }}
                {{ storeNumberStoreData.postalCode }}
              </h2>
            </div>
            <div>
              <input
                type="checkbox"
                name=""
                id=""
                v-model="storeNumberConfirmed"
              />
            </div>
          </div>
        </div>
        <input type="email" placeholder="Email Address" v-model="email" />
      </div>

      <div v-if="groupNumber == 2">
        <select name="country" id="" v-model="country">
          <option class="placeholder" value="" disabled selected>
            Country
          </option>
          <option
            v-for="(country, index) in countries"
            :key="index"
            :value="country.name"
          >
            {{ country.name }}
          </option>
        </select>
        <div v-if="country == 'United States'" class="address">
          <input type="text" placeholder="Address" v-model="address" />
          <input
            type="text"
            placeholder="Apartment or Suite # (if applicable)"
            v-model="address2"
          />
          <input type="text" placeholder="City" v-model="city" />
          <select name="state" id="" v-model="state">
            <option class="placeholder" value="" disabled selected>
              State
            </option>
            <option
              v-for="state in states"
              :key="state.code"
              :value="state.name"
            >
              {{ state.name }}
            </option>
          </select>
          <div class="full-width">
            <input
              type="text"
              class="full-width"
              placeholder="Zipcode"
              v-model="zipcode"
              maxlength="5"
            />
          </div>
        </div>
        <div v-if="country == 'Canada'" class="address">
          <input type="text" placeholder="Address" v-model="address" />
          <input
            type="text"
            placeholder="Apartment or Suite # (if applicable)"
            v-model="address2"
          />
          <input type="text" placeholder="City" v-model="city" />
          <select name="province" id="" v-model="province">
            <option class="placeholder" value="" disabled selected>
              Province
            </option>
            <option
              v-for="(province, index) in provinces"
              :key="index"
              :value="province"
            >
              {{ province.abbrev }}
            </option>
          </select>
          <div class="full-width">
            <input
              type="text"
              class="full-width"
              placeholder="Postal Code"
              v-model="postalCode"
              maxlength="12"
            />
          </div>
        </div>
        <input type="email" placeholder="Email Address" v-model="email" />
      </div>

      <button :class="formIsComplete ? 'btn' : 'btn-grey'" type="submit">
        <span class="btn-align"
          >Complete<img
            src="../assets/right-arrow.svg"
            class="btn-icon-align"
            :class="
              formIsComplete ? 'btn-icon-align' : 'btn-icon-align svg-grey'
            "
        /></span>
      </button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import states from "../assets/states.json";
export default {
  name: "ClaimPrize",
  data() {
    return {
      firstName: "",
      lastName: "",
      issuedBy: "",
      reason: "",
      other: "",
      reasonOtherDescription: "",
      businessUnit: "",
      country: "",
      countries: [
        {name: 'United States'},
        {name: 'Canada'}
      ],
      province: "",
      provinces: [
        {abbrev: "AB"},
        {abbrev: "BC"},
        {abbrev: "MB"},
        {abbrev: "NB"},
        {abbrev: "NL"},
        {abbrev: "NT"},
        {abbrev: "NS"},
        {abbrev: "NU"},
        {abbrev: "ON"},
        {abbrev: "PE"},
        {abbrev: "QC"},
        {abbrev: "SK"},
        {abbrev: "YT"}
      ],
      address: "",
      address2: "",
      city: "",
      state: "",
      zipcode: "",
      postalCode: "",
      email: "",
      states: states,
      storeNumber: "",
      storeNumberError: false,
      storeNumberErorrMessage: "",
      storeNumberStoreData: "",
      storeNumberConfirmed: false,
      store: false,
      loading: false,
      error: false,
      debounce: null,
    };
  },
  methods: {
    submitForm: async function () {
      try {
        if (this.formIsComplete) {
          let submitForm = await axios.post(
            `${process.env.VUE_APP_BASE_API_URL}/users`,
            this.formPayload
          );
          if (submitForm.data.success == true) {
            this.$store.commit("incrementStep");
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    debounceVerifyStoreNumber: function () {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.verifyStoreNumber();
      }, 600);
    },
    verifyStoreNumber: async function () {
      try {
        const storeNumber = await axios.post(
          `${process.env.VUE_APP_BASE_API_URL}/stores/verify`,
          { storeNumber: this.storeNumber }
        );
        this.storeNumberStoreData = storeNumber.data;
        this.storeNumberError = false;
      } catch (error) {
        this.storeNumberError = true;
        this.storeNumberErorrMessage = error.response.data.error;
      }
    },
  },
  computed: {
    formIsComplete: function () {
      if (this.groupNumber == 1) {
        if (
          this.firstName &&
          this.lastName &&
          this.issuedBy &&
          this.reason &&
          this.businessUnit &&
          this.storeNumber &&
          this.email &&
          this.$store.state.prizeCode &&
          this.storeNumberConfirmed
        ) {
          return true;
        } else {
          return false;
        }
      } else if (this.groupNumber == 2) {
        if (
          this.firstName &&
          this.lastName &&
          this.issuedBy &&
          this.reason &&
          this.businessUnit &&
          this.address &&
          this.city &&
          this.country &&
          (this.state || this.province) &&
          (this.zipcode || this.postalCode)&&
          this.email &&
          this.$store.state.prizeCode
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    formPayload: function () {
      if (this.groupNumber == 1) {
        return {
          firstName: this.firstName,
          lastName: this.lastName,
          issuedBy: this.issuedBy,
          reason: this.reason,
          reasonOtherDescription: this.reasonOtherDescription,
          businessUnit: this.businessUnit,
          storeNumber: this.storeNumber,
          email: this.email,
          prizeCode: this.$store.state.prizeCode,
          shirtSize: this.$store.state.shirtSize,
        };
      } else if (this.groupNumber == 2) {
        return {
          firstName: this.firstName,
          lastName: this.lastName,
          issuedBy: this.issuedBy,
          reason: this.reason,
          reasonOtherDescription: this.reasonOtherDescription,
          businessUnit: this.businessUnit,
          address: this.address,
          address2: this.address2,
          city: this.city,
          state: this.state,
          zipcode: this.zipcode,
          email: this.email,
          province: this.province.abbrev,
          postalCode: this.postalCode,
          country: this.country,
          prizeCode: this.$store.state.prizeCode,
          shirtSize: this.$store.state.shirtSize,
        };
      } else {
        return null;
      }
    },
    groupNumber: function () {
      if (
        this.businessUnit == "store" ||
        this.businessUnit == "met" ||
        this.businessUnit == "supply chain"
      ) {
        return 1;
      } else if (
        this.businessUnit == "home services" ||
        this.businessUnit == "retail equipment services" ||
        this.businessUnit == "other"
      ) {
        return 2;
      } else {
        return null;
      }
    },
  },
};
</script>

<style>
@import "../assets/global.scss";

.claim-prize-container h1 {
  font-weight: bold;
  font-size: 14px;
  margin: 10px 0px;
}
.claim-prize-container input[type="text"] {
  border-radius: 5px;
  border: none;
  outline: none;
  box-sizing: border-box;
  margin-top: 20px;
  display: inline-block;
  width: 100%;
  font-size: 14px;
  line-height: 38px;
  padding: 0 10px;
}
.claim-prize-container input[type="email"] {
  border-radius: 5px;
  border: none;
  outline: none;
  box-sizing: border-box;
  margin-top: 20px;
  display: inline-block;
  width: 100%;
  font-size: 14px;
  line-height: 38px;
  padding: 0 10px;
}

.claim-prize-container ::placeholder {
  font-size: 14px;
  color: #575757;
}

.claim-prize-container select {
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 0 10px;
  line-height: 38px;
  box-sizing: border-box;
  margin-top: 20px;
  display: inline-block;
  width: 100%;
  background-color: white;
  background-image: url("~@/assets/down-arrow.svg");
  background-repeat: no-repeat;
  appearance: none;
  background-position-x: 95%;
  background-position-y: 13px;
}
.address input[type="text"] {
  width: 44.5%;
  margin-right: 15px;
  display: inline;
}
.address select {
  width: 50%;
  display: inline;
}
.full-width input[type="text"] {
  width: 100%;
}

.confirm-store-location {
  text-align: left;
  border-radius: 5px;
  margin-top: 20px;
}

.confirm-store-location h1 {
  font-weight: bold;
  font-size: 14px;
  margin: 0px 0px;
}
.confirm-store-location h2 {
  font-weight: normal;
  font-size: 14px;
  margin: 0px;
}
.confirm-store-location-address {
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
}
.confirm-store-location-address input[type="checkbox"] {
  width: 20px;
  height: 20px;
  color: orange;
}
</style>
