<template>
  <div class="winner-container">
    <h1>Congratulations!</h1>
    <h2>You've won a {{ $store.state.prize }}!</h2>
    <img
      class="prize-image"
      v-if="$store.state.prize == 'T-Shirt'"
      src="../assets/prizes/tshirt.jpg"
      alt="T-shirt prize picture"
    />
    <div class="shirt-size-container" v-if="$store.state.prize == 'T-Shirt'">
      <select name="" id="shirtSize" v-model="size">
        <option class="placeholder" value="" disabled selected
          >Select a shirt size</option
        >
        <option v-for="size in shirtSizes" :key="size.index" :value="size">{{
          size
        }}</option>
      </select>
    </div>
    <img
      class="prize-image"
      v-if="$store.state.prize == 'Cap'"
      src="../assets/prizes/hat.jpg"
      alt="Cap prize picture"
    />
    <img
      class="prize-image"
      v-if="$store.state.prize == 'Cooler'"
      src="../assets/prizes/cooler.jpg"
      alt="Cooler prize picture"
    />
    <img
      class="prize-image"
      v-if="$store.state.prize == 'Keychain'"
      src="../assets/prizes/keychain.jpg"
      alt="Keychain prize picture"
    />
    <img
      class="prize-image"
      v-if="$store.state.prize == 'Sharpie'"
      src="../assets/prizes/sharpie.jpg"
      alt="Sharpie prize picture"
    />
    <img
      class="prize-image"
      v-if="$store.state.prize == 'Tumbler'"
      src="../assets/prizes/tumbler_engraved.jpg"
      alt="Tumbler prize picture"
    />
    <img
      class="prize-image"
      v-if="$store.state.prize == 'Lunch Bag'"
      src="../assets/prizes/lunchbag.jpg"
      alt="Lunch bag prize picture"
    />
    <img
      class="prize-image"
      v-if="$store.state.prize == 'Beanie'"
      src="../assets/prizes/beanie.jpg"
      alt="Beanie prize picture"
    />
    <button
      class="btn"
      @click="claimHandler"
      v-if="$store.state.prize !== 'T-Shirt'"
    >
      <span class="btn-align"
        >Next<img src="../assets/right-arrow.svg" class="btn-icon-align"
      /></span>
    </button>

    <button
      :class="size ? 'btn' : 'btn-grey'"
      @click="claimHandler"
      v-if="$store.state.prize == 'T-Shirt'"
    >
      <span class="btn-align"
        >Next<img src="../assets/right-arrow.svg" class="btn-icon-align"
      /></span>
    </button>
  </div>
</template>

<script>
export default {
  name: "Winner",
  data() {
    return {
      shirtSizes: [
        "Small",
        "Medium",
        "Large",
        "X-Large",
        "2X-Large",
        "3X-Large",
        "4X-Large",
        "5X-large",
      ],
    };
  },
  methods: {
    claimHandler: function() {
      if (this.$store.state.prize == "T-Shirt") {
        if (this.$store.state.shirtSize) {
          this.$store.commit("incrementStep");
        }
      } else if (this.$store.state.prize !== "T-Shirt") {
        this.$store.commit("incrementStep");
      }
    },
  },
  computed: {
    size: {
      get: function() {
        return this.$store.state.shirtSize;
      },
      set: function(value) {
        this.$store.commit("setShirtSize", value);
      },
    },
  },
};
</script>

<style>
@import "../assets/global.scss";

.winner-container h1 {
  font-weight: bold;
  font-size: 21px;
}
.winner-container h2 {
  font-weight: normal;
  font-size: 14px;
  margin: 10px 0px;
}
/* .winner-container img {
  width: 297px;
} */
.prize-image {
  width: 286px;
}
.winner-container select {
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 20px;
  display: inline-block;
  width: 100%;
  background-color: white;
  background-image: url("~@/assets/down-arrow.svg");
  background-repeat: no-repeat;
  appearance: none;
  background-position-x: 95%;
  background-position-y: 13px;
}

.winner-container ::placeholder {
  font-size: 14px;
  color: #575757;
}
</style>
