<template>
  <div class="progress-container">
    <div class="progress-wrapper" v-if="step == 1">
      <div class="current-circle">1</div>
      <hr />
      <div class="future-circle">2</div>
      <hr />
      <div class="future-circle">3</div>
    </div>
    <div class="progress-wrapper" v-if="step == 2">
      <div class="fill-circle">1</div>
      <hr class="hr-orange" />
      <div class="current-circle">2</div>
      <hr />
      <div class="future-circle">3</div>
    </div>
    <div class="progress-wrapper" v-if="step == 3">
      <div class="fill-circle">1</div>
      <hr class="hr-orange" />
      <div class="fill-circle">2</div>
      <hr class="hr-orange" />
      <div class="current-circle">3</div>
    </div>
    <div class="progress-wrapper" v-if="step == 4">
      <div class="fill-circle">1</div>
      <hr class="hr-orange" />
      <div class="fill-circle">2</div>
      <hr class="hr-orange" />
      <div class="fill-circle">3</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressCounter",
  computed: {
    step: function() {
      return this.$store.state.step;
    },
  },
};
</script>

<style lang="scss">
@import "../assets/global.scss";
.progress-container {
  margin: 20px;
}
.progress-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.current-circle {
  width: 35px;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  border: 2px solid $orange;
}
.future-circle {
  width: 35px;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  border: 2px solid #585858;
}
.fill-circle {
  width: 35px;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  border: 2px solid $orange;
  background-color: $orange;
}
hr {
  width: 50px;
  border: 1px solid #585858;
}
.hr-orange {
  border: 1px solid $orange;
}
</style>
