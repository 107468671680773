<template>
    <SelectApp />
</template>

<script>
import SelectApp from "@/components/SelectApp.vue";

export default {
    components: {
        SelectApp,
    },
};
</script>
