import Vue from "vue";
import VueRouter from "vue-router";
import Redeem from "../views/Redeem.vue";
import Contact from "../views/Contact.vue";
import PLD from "../views/PLD.vue";
import Home from "../views/Home.vue";
// import Base from '../views/Base.vue'

Vue.use(VueRouter);

const routes = [
    { path: "/", name: "Home", component: Home },
  {
    path: "/redeem",
    name: "Redeem",
    component: Redeem,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/pld",
    name: "PLD",
    component: PLD
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
