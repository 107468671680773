<template>
  <div class="container">
    <div class="wrapper">
      <div class="header">
        <img src="../assets/safety.svg" alt="" />
      </div>
      <div v-if="!sent" class="content">
        <p>Need Assistance?  Please fill out the form below.</p>
        <form @submit.prevent="submitForm">
          <input type="text" placeholder="Name" v-model="name" required />
          <input type="email" placeholder="Email" v-model="email" required />
          <input type="text" placeholder="Prize Code (optional)" v-model="prize" />
          <textarea rows="10" placeholder="Your Message" v-model="message" required></textarea>

          <button class="btn">
            <span class="btn-align"
              >Submit<img src="../assets/right-arrow.svg" class="btn-icon-align"
            /></span>
          </button>
        </form>
      </div>
      <div v-if="sent" class="content">
        <p>Thank You!  Your message has been sent.</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      name: '',
      email: '',
      prize: '',
      message: '',
      sent: false
    }
  },
  methods: {
    submitForm() {
      if(this.name && this.email && this.message) {
        const data = {
          name: this.name,
          email: this.email,
          prize: this.prize,
          message: this.message
        }
        axios.post(process.env.VUE_APP_BASE_API_URL + '/send-message', data)
        .then((d) => {
          return d.status === 200 ? this.sent = true : this.sent = false
        })
        .catch(e => console.log(e))
      }
    }
  }
}
</script>

<style>
@import "../assets/global.scss";

.container {
  text-align: center;
  margin: 0px auto;
  width: 428px;
  min-height: 100vh;
  display: flex;
}
.wrapper {
  padding: 60px;
}
.header {
  width: 300px;
  height: 125px;
  margin: 0 auto;
}
.header img {
  max-width: 300px;
  outline: 3px solid white;
  padding: 20px;
}
.content {
  padding: 10px;
}
p {
  margin: 30px 0;
}
form {
  margin-top: 30px;
}
input[type="text"],
input[type="email"],
textarea {
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 10px;
  width: 100%;
  max-width: 227px;
  box-sizing: border-box;
  text-align: left;
  margin-bottom: 15px;
  font-family: "Helvetica Neue", Helvetica, Arial;
}

.btn-icon-align {
  margin-left: 20px;
}

@media screen and (max-width: 450px) {
  .container {
    width: 100%;
  }
  .wrapper {
    padding: 60px 10px;
  }
}
</style>
